@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f3f4f6;

}

.menu-item:hover, .menu-item:focus, .menu-item:active{
    @apply bg-primary;
    @apply text-black;
    @apply font-bold;
}

.glow-border {
    position: relative;
    z-index: 1;
  }

  .glow-border::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: inherit;
    z-index: -1;
    box-shadow: 0 0 20px 0px #184690;
    animation: rotateGlow 5s linear infinite;
  }

  @keyframes rotateGlow {
    0% {
        box-shadow: 0 0 20px 5px #FFFFFF;
    }
    25% {
      box-shadow: 0 0 10px 5px #FFFFFF;
    }
    50% {
      box-shadow: 0 0 20px 5px #FFFFFF;
    }
    75% {
      box-shadow: 0 0 10px 5px #FFFFFF;
    }
    100% {
      box-shadow: 0 0 20px 5px #EFF0F2;
    }
  }

  .glass {
    background-color: rgb(255, 255, 255); /* Adjust the RGBA values for color and opacity */
    /* -webkit-backdrop-filter: blur(10px); */
    /* backdrop-filter: blur(10px);  */
    border-radius: 10px; /* Optional: adds rounded corners */
    border: 1px solid rgb(255, 255, 255); /* Optional: adds a light border */
  }

  .navline {
    background: linear-gradient(to right, #7497D8, #9CA3AF, #7497D8, #7497D8);
    color: #7497D8;

}

/* HTML: <div class="loader"></div> */
.loader {
    --r1: 154%;
    --r2: 68.5%;
    overflow: hidden;
    border-radius: 50%;
    background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
                radial-gradient(var(--r1) var(--r2) at bottom, #269af2 79.5%, #0000 80%),
                radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
                #ccc;
    background-size: 50.5% 220%;
    background-repeat: no-repeat;
    animation: l9 2s infinite linear;
}

.testimonial_card{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@keyframes l9 {
    0% {background-position: 0% 0%, 100% 0%, 200% 0%;}
    30% {background-position: 0% 33%, 100% 33%, 200% 33%;}
    66% {background-position: -100% 66%, 0% 66%, 100% 66%;}
    100% {background-position: 0% 100%, 100% 100%, 200% 100%;}
}

/* Remove the scroll bar */
::-webkit-scrollbar {
    display: none;
}

/* Add a hover effect for the menu items */
.menu-item:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the glass div */
.glass:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Add a glow effect for the testimonial cards */
.testimonial_card:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 5px #269af2;
}

/* Add a glow effect for the navigation bar */
.navline:hover {
    background: linear-gradient(to right, #7497D8, #9CA3AF, #7497D8, #7497D8);
    color: #269af2;
}

/* Add a glow effect for the loader */
.loader:hover {
    background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
                radial-gradient(var(--r1) var(--r2) at bottom, #269af2 79.5%, #0000 80%),
                radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
                #ccc;
    background-size: 50.5% 220%;
    background-repeat: no-repeat;
    animation: l9 2s infinite linear;
}

/* Add a glow effect for the background */
body:hover {
    background-color: #f3f4f6;
}

/* Add a glow effect for the footer */
footer:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the contact form */
.contact-form:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Add a glow effect for the footer links */
.footer-link:hover {
    color: #269af2;
}

/* Add a glow effect for the social media icons */
.social-media-icon:hover {
    color: #269af2;
}

/* Add a glow effect for the search bar */
.search-bar:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Add a glow effect for the menu toggle button */
.menu-toggle:hover {
    background-color: #269af2;
}

/* Add a glow effect for the menu items when the menu is open */
.menu-item.open:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the glass div when the menu is open */
.glass.open:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Add a glow effect for the testimonial cards when the menu is open */
.testimonial_card.open:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 5px #269af2;
}

/* Add a glow effect for the navigation bar when the menu is open */
.navline.open:hover {
    background: linear-gradient(to right, #7497D8, #9CA3AF, #7497D8, #7497D8);
    color: #269af2;
}

/* Add a glow effect for the loader when the menu is open */
.loader.open:hover {
    background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
                radial-gradient(var(--r1) var(--r2) at bottom, #269af2 79.5%, #0000 80%),
                radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
                #ccc;
    background-size: 50.5% 220%;
    background-repeat: no-repeat;
    animation: l9 2s infinite linear;
}

/* Add a glow effect for the background when the menu is open */
body.open:hover {
    background-color: #f3f4f6;
}

/* Add a glow effect for the footer when the menu is open */
footer.open:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the contact form when the menu is open */
.contact-form.open:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Add a glow effect for the footer links when the menu is open */
.footer-link.open:hover {
    color: #269af2;
}

/* Add a glow effect for the social media icons when the menu is open */
.social-media-icon.open:hover {
    color: #269af2;
}

/* Add a glow effect for the search bar when the menu is open */
.search-bar.open:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

/* Add a glow effect for the menu toggle button when the menu is open */
.menu-toggle.open:hover {
    background-color: #269af2;
}


/* Add a glow effect for the menu items when the menu is open and the user is hovering over a submenu */
.menu-item.open:hover.submenu {
    display: block;
}

.menu-item.open:hover.submenu li:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the glass div when the menu is open and the user is hovering over a submenu */
.glass.open:hover.submenu {
    display: block;
}

.glass.open:hover.submenu li:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the testimonial cards when the menu is open and the user is hovering over a submenu */
.testimonial_card.open:hover.submenu {
    display: block;
}

.testimonial_card.open:hover.submenu li:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the navigation bar when the menu is open and the user is hovering over a submenu */
.navline.open:hover.submenu {
    display: block;
}

.navline.open:hover.submenu li:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the loader when the menu is open and the user is hovering over a submenu */
.loader.open:hover.submenu {
    display: block;
}

.loader.open:hover.submenu li:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the background when the menu is open and the user is hovering over a submenu */
body.open:hover.submenu {
    display: block;
}

body.open:hover.submenu li:hover {
    background-color: #269af2;
    color: white;
}

/* Add a glow effect for the footer when the menu is open and the user is hovering over a submenu */
footer.open:hover.submenu {
    display: block;
}

footer.open:hover.submenu li:hover {
    background-color: #269af2;
    color: white;
}









